import React from 'react'
import Script from 'next/script'
import { OPTIMIZE_CONTAINER_ID } from '@/services/Configuration'
import { log } from '@/services/Log'

const GoogleOptimizeScript: React.FC = () => (
  <Script
    strategy="beforeInteractive"
    onLoad={() => {
      log('Google Optimize script loaded')
    }}
    src={`https://www.googleoptimize.com/optimize.js?id=${OPTIMIZE_CONTAINER_ID}`}
  />
)

export default GoogleOptimizeScript
